import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { loadUserData } from './loadUserData';

export interface LoadDataResult {
  loyaltyProgram: LoyaltyProgram;
  pointsBalance: number;
  rewardsAvailable: number;
}

const WARMUP_DATA_KEY = 'loyaltyStatusWidgetLoadData';

export async function loadData(flowAPI: ControllerFlowAPI): Promise<LoadDataResult> {
  const { httpClient } = flowAPI;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { withErrorHandler } = flowAPI.errorHandler;
  const { isSSR, isViewer } = flowAPI.environment;
  const useWarmupData = isViewer;
  const { warmupData } = wixCodeApi.window;
  const isLoggedIn = wixCodeApi.user.currentUser.loggedIn;

  // Reuse backend API responses from SSR on the client-side
  if (useWarmupData && !isSSR) {
    const dataFromSSR = warmupData.get(WARMUP_DATA_KEY);
    if (dataFromSSR) {
      return dataFromSSR;
    }
  }

  const loyaltyProgramPromise = withErrorHandler(() => httpClient.request(getLoyaltyProgram({})), {
    errorCodesMap: {},
  });

  const userDataPromise = isLoggedIn
    ? loadUserData(flowAPI)
    : Promise.resolve({ pointsBalance: 0, rewardsAvailable: 0 });

  const [{ pointsBalance, rewardsAvailable }, loyaltyProgramResponse] = await Promise.all([
    userDataPromise,
    loyaltyProgramPromise,
  ]);

  const loyaltyProgram = loyaltyProgramResponse?.data.loyaltyProgram!;

  const data = {
    rewardsAvailable,
    loyaltyProgram,
    pointsBalance,
  };

  // Forward backend API responses from SSR to client-side
  if (useWarmupData && isSSR) {
    warmupData.set(WARMUP_DATA_KEY, data);
  }

  return data;
}
