import { getCurrentMemberCoupons } from '@wix/ambassador-loyalty-v1-coupon/http';
import { LoyaltyCoupon } from '@wix/ambassador-loyalty-v1-coupon/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyCoupons(flowAPI: ControllerFlowAPI): Promise<LoyaltyCoupon[]> {
  const { httpClient } = flowAPI;
  const { withErrorHandler } = flowAPI.errorHandler;

  const response = await withErrorHandler(() => httpClient.request(getCurrentMemberCoupons({})), {
    errorCodesMap: {},
  });

  return response.data.loyaltyCoupons!;
}
