import { getCurrentMemberAccount } from '@wix/ambassador-loyalty-v1-account/http';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyAccount(flowAPI: ControllerFlowAPI): Promise<LoyaltyAccount> {
  const { httpClient } = flowAPI;
  const { withErrorHandler } = flowAPI.errorHandler;

  try {
    const response = await withErrorHandler(() => httpClient.request(getCurrentMemberAccount({})), {
      errorCodesMap: {},
    });

    return response.data.account!;
  } catch (error) {
    // Loyalty account doesn't exist
    if (error instanceof Error && error.message.includes('404')) {
      return {};
    }
    throw error;
  }
}
